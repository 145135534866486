<template>
  <Java />
</template>
<script>
import Java from './components/Java.vue'

export default {
  name: 'App',
  components: {
    Java
  }
}
</script>

<style>
  @import './assets/bootstrap/css/bootstrap.min.css';
</style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
