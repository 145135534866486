<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <img alt="Java Rage" src="../assets/java-fail-comic.jpg" class="img-fluid">
  </div>
</template>

<script>

export default {
  name: 'Java'
}
</script>